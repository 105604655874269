.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-tile-container img {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}

.leaflet-container .leaflet-control-attribution {
  background-color: #00000070;
  color: #ccc;
  font-size: 10px;
}

.leaflet-container .leaflet-control-attribution > a {
  color: #ccc;
}

.markercluster-map {
  height: 90vh;
}

/* Customize the Clustered Markers */
.marker-cluster-custom {
  background: #9370db;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.buttons-control {
  bottom: 10px;
  right: 10px;
}

.button-control {
  background-color: #fff !important;
  width: 30px;
  height: 30px;
  border-radius: 4px !important;
  color: #000 !important;
}

.marker-position {
  background-color: #3388ffd3;
  border: 3px solid #245fb3;
  border-radius: 50%;
}
